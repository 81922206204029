
import { Mixins, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import Dark from '@/mixins/dark'
import { UITheme } from '@/store/ui/theme.interface'

/**
 * Standard-Hintergrund-Container für Light und Dark Mode
 */
@Component({})
export default class DefaultBackground extends Mixins(Dark) {
  /**
   * Hintergrundbild im Light Mode.
   */
  public backgroundLight: string = require('@/assets/login-background.jpg')

  /**
   * Hintergrundbild im Dark Mode.
   */
  public backgroundDark: string = require('@/assets/login-background-dark.jpg')

  /**
   * Einstellungen vom Theme, im UI-Store
   */
  @Getter('ui/theme')
  public uiStoreTheme!: UITheme

  /**
   * Gibt die CSS-Klassen für das Hintergundbild, wenn eine Hintergundfarbe im
   * Theme gesetzt worden ist. Variiert je nachdem, ob der Dark Mode aktiv ist.
   *
   * @returns CSS-Klassen für das Hintergrundbild
   */
  public get imageClass(): string {
    const cfg = this.dark ? this.uiStoreTheme.dark : this.uiStoreTheme.light
    return `login__background_image${
      cfg.backgroundColor ? ' loginBackgroundColor' : ''
    }`
  }

  /**
   * Gibt das Hintergundbild zurück. Dieses wird durch die Theme-Einstellungen
   * zurückgegeben. Das Fallback Hintergrundbild variiert je nachdem, ob der
   * Dark Mode aktiv ist.
   *
   * @returns Pfad zum Hintergrundbild.
   */
  public get background(): string {
    const config = this.dark ? this.uiStoreTheme.dark : this.uiStoreTheme.light

    if (config.backgroundImage) {
      const file = config.backgroundImage.replace(/[\\/]/g, '_')
      return `${process.env.BASE_URL || '/'}custom/${file}`
    }

    return this.dark ? this.backgroundDark : this.backgroundLight
  }
}

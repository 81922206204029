
import { Component, Vue } from 'vue-property-decorator'
import SetPasswordMask from '@/components/SetPasswordMask/SetPasswordMask.vue'
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue'
import DefaultBackground from '@/components/DefaultBackground/DefaultBackground.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * "Passwort zurücksetzen"-View
 */
@Component({
  components: {
    SetPasswordMask,
    BaseFrame,
    LanguagePicker,
    DefaultBackground
  }
})
export default class PasswordReset extends Vue {
  /**
   * Token, der an die "Passwort Zurücksetzen"-Maske weitergegeben wird.
   * Wird mit der URL übergeben.
   */
  public token?: string

  /**
   * Liest den Token aus der URL aus und speichert diesen in einer Variable.
   */
  public created(): void {
    this.token = this.$route.params.token
  }
}


import { Component, Vue } from 'vue-property-decorator'
import setPasswordContent from '@/components/SetPasswordContent/SetPasswordContent.vue'

/**
 * Maske, die es dem Benutzer ermöglicht sein Passwort zu ändern, falls es noch
 * nicht geändert wurde
 */
@Component({
  components: {
    setPasswordContent
  }
})
export default class SetPasswordMask extends Vue {}
